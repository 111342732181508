import React from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { getCustomNoLocationText } from '../../../redux/plugins/selectors';
import { getFilters } from '../../../redux/experiences/selectors';
import { noResultsMessage } from '../../../shared/utils/staticCssClasses';
import { smallCssBreak } from '../../common_styles';

const StyledNoResults = styled('div')`
  padding: 10px;
  margin: 110px 0 10px;
  color: #333333;
  font-size: 16px;
  text-align: center;
  @media (max-width: ${smallCssBreak}) {
    font-size: 14px;
  }
`;

enum NoResultsReason {
  LOCATION,
  GUEST,
  DATE,
  SEARCH,
  MULTIPLE_INCLUDING_SEARCH,
  MULTIPLE_EXCLUDING_SEARCH,
  NO_REASON
}

type NoResultsPropsType = {
  useKmForDistance: boolean;
};

export const NoResults = (props: NoResultsPropsType) => {
  const { useKmForDistance } = props;
  const { t } = useTranslation();
  const customNoLocationText = useSelector(getCustomNoLocationText);
  const { startDate, guestCount, searchQuery, locationQuery, locationRadius } =
    useSelector(getFilters);

  const getNoResultsReason = () => {
    if (locationQuery && [startDate, guestCount, searchQuery].every(e => !e)) {
      return NoResultsReason.LOCATION;
    }
    if (guestCount && [startDate, locationQuery, searchQuery].every(e => !e)) {
      return NoResultsReason.GUEST;
    }
    if (startDate && [guestCount, locationQuery, searchQuery].every(e => !e)) {
      return NoResultsReason.DATE;
    }
    if (searchQuery) {
      if ([startDate, guestCount, locationQuery].filter(e => !!e).length >= 1) {
        return NoResultsReason.MULTIPLE_INCLUDING_SEARCH;
      }
      return NoResultsReason.SEARCH;
    }
    if ([startDate, guestCount, locationQuery].filter(e => !!e).length >= 2) {
      return NoResultsReason.MULTIPLE_EXCLUDING_SEARCH;
    }
    return NoResultsReason.NO_REASON;
  };

  const sanitizeString = (str: string): string => {
    return DOMPurify.sanitize(str, { ALLOWED_TAGS: [] });
  };

  const getNoResultsText = () => {
    switch (getNoResultsReason()) {
      case NoResultsReason.LOCATION:
        return (
          customNoLocationText ||
          t('noResultsWithin', {
            distance: locationRadius,
            units: useKmForDistance ? t('kilometers') : t('miles'),
            location: sanitizeString(locationQuery)
          })
        );
      case NoResultsReason.SEARCH:
        return `${t('noSearchResults', { searchQuery: sanitizeString(searchQuery) })}`;
      case NoResultsReason.MULTIPLE_INCLUDING_SEARCH:
        return `${t('noSearchResults', { searchQuery: sanitizeString(searchQuery) })}<br />${t(
          'widenSearch'
        )}`;
      case NoResultsReason.MULTIPLE_EXCLUDING_SEARCH:
        return `${t('noFilterResults')}<br />${t('widenSearch')}`;
      case NoResultsReason.GUEST:
        return t('noResultsGuests');
      case NoResultsReason.DATE:
        return t('noResultsDates');
      case NoResultsReason.NO_REASON:
        return t('noResultsFuture');
      default:
        return t('noResultsFuture');
    }
  };

  return (
    <StyledNoResults className={noResultsMessage}>{parse(getNoResultsText())}</StyledNoResults>
  );
};
